export const colorPrimary: string = '#00b96b'

export const backgroundColor: string = '#eff6ff'

export const colorSecondary: string = 'whitesmoke'

export const colorText: string = 'black'
export const colorTextSecondary: string = 'white'

export const colorHighlights: string = '388E3C'
export const fontSize: string = '16px'
export const fontSizeSmall: string = '14px'

export const blockGap: number = 20
// export const colorText: string = 'black'

