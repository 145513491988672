import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import LayoutWrapper from "./components/LayoutWrapper";
import Dev from "./pages/Dev";
import PrivacyPolicy from "./pages/PrivacyPolicy"

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    // <React.StrictMode>
    <Router>
        <Routes>
            {/*<Route path="/signup" element={<LayoutWrapper component={Signup}/>}/>*/}
            {/*<Route path="/login" element={<LayoutWrapper component={Login}/>}/>*/}

            <Route path="/dev" element={<LayoutWrapper component={Dev}/>}/>
            <Route path="/privacy-policy" element={<LayoutWrapper component={PrivacyPolicy}/>}/>

            {/*<Route path="/profile" element={<LayoutWrapper component={Profile}/>}/>*/}
            {/*<Route path="/alumni" element={<LayoutWrapper component={Alumni}/>}/>*/}
            <Route path="/" element={<LayoutWrapper component={App}/>}/>
            <Route path="/*" element={<LayoutWrapper component={App}/>}/>

        </Routes>
    </Router>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
