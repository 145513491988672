import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom"
import {ButtonDemo} from "./components/ButtonDemo"
import {Col, Row, Typography} from "antd"
import BottomLeftBackround from './background/square.svg';
import TopRightBackground from './background/square2.svg';

import ChartPlotData from './icons/chart_plot.json'

import MmmIcon from './icons/ai_icon.png'
import CookielessIcon from './icons/cookieless.png'

import {
    BarChartOutlined,
    CloudOutlined,
    DatabaseOutlined,
    DollarOutlined,
    FundOutlined,
    LineChartOutlined,
    RiseOutlined,
    SafetyOutlined,
    SolutionOutlined
} from '@ant-design/icons';
import {blockGap, colorPrimary} from "./global"
import PrettyPlot from "./components/PrettyPlot"
import {useIsMobile} from "./components/LayoutWrapper"
import {analytics} from "./utils/firebase"
import {logEvent} from 'firebase/analytics';

const {Title} = Typography

export function useNavigateHome(page_link = '') {
    const location = useLocation()
    let page_title = location.pathname.substring(1)
    const navigate = useNavigate()
    console.log({page_title})

    useEffect(() => {
        if (page_title !== page_link) {
            console.log('redirecting')
            navigate(page_link)
        }
    }, [page_title])
}

export const CustomHeader: React.FC<any> = (
    {
        children,
        style
    }
) => {
    const isMobile = useIsMobile()

    return (
        <Title

            level={2}
            style={{
                // fontSize: '30px',
                fontWeight: 'bold',
                color: 'black',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: isMobile ? 'center' : 'left',
                paddingLeft: isMobile ? '10px' : undefined,
                paddingRight: isMobile ? '10px' : undefined,
                ...style
            }}>
            {children}
        </Title>
    )
}

const ChartPlot: React.FC = () => {
    const isMobile = useIsMobile()
    return (
        <div style={{margin: '5vh'}}>
            <PrettyPlot
                // todo: fix plot display for mobile
                // plotData={isMobile ? ChartPlotDataMobile : ChartPlotData}
                plotData={ChartPlotData}
            />
        </div>
    )
}
export const CustomP: React.FC<any> = (
    {
        children,
        style
    }
) => {
    const isMobile = useIsMobile()
    return (
        <p
            style={{
                fontSize: isMobile ? '18px' : '22px',
                color: 'grey',
                textAlign: isMobile ? 'center' : 'left',
                paddingLeft: '5vw',
                paddingRight: '5vw',
                ...style
            }}>
            {children}
        </p>
    )
}

export const CustomPWhite: React.FC<any> = (
    {
        children,
        style
    }
) => {
    return (
        <CustomP
            style={{
                color: 'white',
                ...style
            }}>
            {children}
        </CustomP>
    )
}

export const CustomHeader2: React.FC<any> = (
    {
        children,
        style,
        icon = null
    }
) => {
    const isMobile = useIsMobile()
    return (
        <p
            style={{
                fontSize: '24px',
                fontWeight: 'bold',
                color: 'black',
                textAlign: isMobile ? 'center' : 'left',
                ...style
            }}>
            {icon && <span style={{marginRight: '8px'}}>{icon}</span>}
            {children}
        </p>
    )
}

export const CustomP2: React.FC<any> = (
    {
        children,
        style
    }
) => {
    const isMobile = useIsMobile()
    return (
        <p
            style={{
                fontSize: isMobile ? '18px' : '20px',
                paddingLeft: isMobile ? '10px' : null,
                paddingRight: isMobile ? '10px' : null,

                color: 'grey',
                textAlign: isMobile ? 'center' : 'left',

                ...style
            }}>
            {children}
            <br/><br/>
        </p>
    )
}

export const CustomP2White: React.FC<any> = (
    {
        children,
        style
    }
) => {
    return (
        <CustomP2
            style={{
                color: '#efefefff',
                ...style
            }}>
            {children}
        </CustomP2>
    )
}

const Homepage: React.FC = () => {
    const isMobile = useIsMobile()
    return (
        <div style={{
            height: isMobile ? '73vh' : '83vh',
            display: 'flex',
            // justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            backgroundImage: `url(${BottomLeftBackround}), url(${TopRightBackground})`,
            backgroundPosition: 'calc(100% - 90vw) 10vh, 85vw -10vh',
            backgroundRepeat: 'no-repeat, no-repeat',
            backgroundSize: '50vh, 45vh',

        }}

        >

            <Title
                style={{
                    marginTop: '100px',
                    width: isMobile ? '80%' : '40%',
                    textAlign: 'center',
                }}
            >
                Unleash the power of Marketing
            </Title>
            <CustomP>
                Optimize your Media Investment with Artificial Intelligence to increase your sales
                <br/><br/>
            </CustomP>
            <ButtonDemo

                style={{
                    fontSize: '20px',
                    width: '250px',
                    height: '70px',
                    // fontWeight: 'italic'
                }}

            />


        </div>
    )
}

const Section: React.FC<any> = (
    {
        children,
        justifyContent = 'left',
        backgroundColor = colorPrimary,
    }
) => {
    const isMobile = useIsMobile()
    const justifyContent_ = isMobile ? 'center' : justifyContent
    return (
        <div style={{
            // height: heightGap,
            marginLeft: isMobile ? '1vh' : '3vh',
            marginRight: isMobile ? '1vh' : '3vh',
            marginBottom: isMobile ? '10vh' : '20vh',
            // marginTop: '30vh',
            display: 'flex',
            justifyContent: justifyContent_


        }}>
            <div
                style={{
                    backgroundColor: backgroundColor,
                    borderRadius: '10px',
                    display: 'flex',
                    width: '80%',
                    flexDirection: 'column',
                    paddingBottom: '5vh',
                    paddingTop: '2vh'

                }}
            >
                {children}
            </div>
        </div>
    )

}

function getHeightGap(height: number) {
    return {
        height: `${height}vh`,
        heightGap: `${height + blockGap}vh`
    }

}

const SectionChart: React.FC = () => {
    const isMobile = useIsMobile()
    const CustomHeaderValue: React.FC = () => {
        return (
            <>
                <CustomHeader
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',

                    }}

                >
                    Marketing optimization platform
                </CustomHeader>
                <CustomPWhite

                    style={{
                        paddingLeft: isMobile ? '10vw' : null,
                        paddingRight: isMobile ? '10vw' : null,
                    }}

                >
                    Laia uses Marketing Mix Modeling to boost your sales, optimize your budget spend and maximize your
                    ROI
                </CustomPWhite>
            </>
        )
    }
    return (
        <Section>
            {isMobile ?
                <>
                    <CustomHeaderValue/>
                    <ChartPlot/>
                </>
                :
                <Row

                >
                    <Col span={8}
                         offset={1}


                    >
                        <div>
                            <p>
                                <br/><br/>
                            </p>
                            <CustomHeaderValue/>
                        </div>
                    </Col>
                    <Col
                        span={12}
                        offset={1}
                    >
                        <ChartPlot/>


                    </Col>
                </Row>
            }
        </Section>
    )
}

const SectionBug: React.FC = () => {
    const isMobile = useIsMobile()

    const MarketingData: React.FC = () => {
        return (
            <>
                <CustomHeader2
                    icon={<BarChartOutlined/>}>
                    Marketing data
                </CustomHeader2>
                <CustomP2>
                    Laia analyzes Digital Campaigns, Offline Campaigns, Newsletters and any other Marketing activity you
                    track
                </CustomP2>
            </>
        )
    }

    const RevenueData: React.FC = () => {
        return (
            <>
                <CustomHeader2
                    icon={<LineChartOutlined/>}>
                    Revenue data
                </CustomHeader2>
                <CustomP2>
                    Include your sales data and Laia will build the causal relationships with your media channels
                </CustomP2>
            </>
        )

    }

    const ExternalFactors: React.FC = () => {
        return (
            <>
                <CustomHeader2
                    icon={<CloudOutlined/>}>
                    External factors
                </CustomHeader2>
                <CustomP2>
                    Any external factor that can be measured and tracked can be added to the model: Weather,
                    Geopolitical events, Competitor Data
                </CustomP2>
            </>
        )
    }

    const ROI: React.FC = () => {
        return (
            <>
                <CustomHeader2
                    icon={<RiseOutlined/>}>
                    ROI per channel
                </CustomHeader2>
                <CustomP2>
                    Laia calculates the true ROI per channel, understanding cross-media effects
                </CustomP2>
            </>
        )
    }

    const BudgetOptimizer: React.FC = () => {
        return (
            <>
                <CustomHeader2
                    icon={<FundOutlined/>}>
                    Budget optimizer
                </CustomHeader2>
                <CustomP2>
                    Laia optimizes your spend per channel, so with the same Budget you get +20% in sales
                </CustomP2>
            </>
        )
    }

    const ActionPlan: React.FC = () => {
        return (
            <>
                <CustomHeader2
                    icon={<SolutionOutlined/>}>
                    Action plan
                </CustomHeader2>
                <CustomP2>
                    Laia builds for you multiple prediction scenarios so you can choose the ones with best performance.
                </CustomP2>
            </>
        )
    }


    return (
        <Section justifyContent='end'
                 backgroundColor='whitesmoke'
        >

            <CustomHeader
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                }}

            >
                Measure and optimize
            </CustomHeader>


            {isMobile ?
                <>
                    <MarketingData/>
                    <RevenueData/>
                    <ExternalFactors/>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%'

                        }}
                    >
                        <img
                            style={{width: '90%'}}

                            src={MmmIcon}/>
                    </div>
                    <ROI/>
                    <BudgetOptimizer/>
                    <ActionPlan/>
                </>
                :
                <Row>
                    <Col span={7}
                         offset={1}
                    >

                        <MarketingData/>
                        <RevenueData/>
                        <ExternalFactors/>
                    </Col>
                    <Col span={7}
                         offset={1}
                    >
                        <img
                            style={{width: '90%'}}

                            src={MmmIcon}/>
                    </Col>
                    <Col span={6}
                         offset={1}
                    >
                        <ROI/>
                        <BudgetOptimizer/>
                        <ActionPlan/>
                    </Col>
                </Row>
            }
        </Section>
    )
}


const SectionCookieLess: React.FC = () => {
    const {height, heightGap} = getHeightGap(50)

    const isMobile = useIsMobile()

    const CookielessFuture: React.FC = () => {
        return (
            <>
                <CustomHeader2
                    icon={<SafetyOutlined/>}>
                    Cookieless future
                </CustomHeader2>
                <CustomP2White>
                    No user-tracking needed, no third party cookies needed. <br/>In MMM, Data is collected
                    aggregated. This feature makes Laia your must-have tool in the coming Cookieless world
                </CustomP2White>
            </>
        )
    }

    const AffordableAi: React.FC = () => {
        return (
            <>
                <CustomHeader2
                    icon={<DollarOutlined/>}>
                    Affordable Ai
                </CustomHeader2>
                <CustomP2White>
                    AI is more affordable than ever. <br/>
                    Laia includes a powerful AI Engine to build millions of
                    simulations and choose the best Optimized scenario for you. But a reasonable cost.
                </CustomP2White>
            </>
        )
    }

    const UnifiedData: React.FC = () => {
        return (
            <>
                <CustomHeader2
                    icon={<DatabaseOutlined/>}>
                    Unified data
                </CustomHeader2>
                <CustomP2White>
                    Data is everywhere. <br/>
                    MMM needs data to work. Data is currently more accessible than
                    ever.Laia includes curated data from all your media channels <br/>
                    (Google Ads, Meta, TikTok, etc) <br/>
                    plus off-line campaigns <br/>
                    (TV, OOH, etc)
                </CustomP2White>
            </>
        )
    }
    return (
        <Section>
            <CustomHeader
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    textAlign: 'center',
                }}

            >
                Get ready for a cookieless world thanks to Marketing Mix Modeling
            </CustomHeader>

            <CustomPWhite
                style={{
                    // width: '80%',
                    // color: 'white',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    paddingLeft: '10vw',
                    paddingRight: '10vw',
                }}
            >
                Laia enhances the power of Machine Learning algorithms to build the best spending recommendations
                thanks to Marketing Mix Modeling (MMM) <br/>
                Why is MMM more relevant than ever?
            </CustomPWhite>

            {isMobile ?
                <>
                    <CookielessFuture/>
                    <AffordableAi/>
                    <UnifiedData/>

                </> :
                <Row>
                    <Col span={7}
                         offset={1}
                    >
                        <CookielessFuture/>

                    </Col>
                    <Col
                        span={7}
                        offset={1}
                    >

                        <AffordableAi/>
                    </Col>
                    <Col
                        span={6}
                        offset={1}

                    >
                        <UnifiedData/>
                    </Col>


                </Row>
            }
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    // marginTop: '20px',
                    width: '100%'
                }}
            >

                <img
                    style={{

                        width: isMobile ? '40vw' : '10vw'
                    }}

                    src={CookielessIcon}/>
            </div>
        </Section>
    )
}

const Bottom: React.FC = () => {
    const isMobile = useIsMobile()
    return (
        <div
            style={{
                // height: isMobile ? '20vh' : '87vh',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                marginBottom: isMobile ? '10vh' : '20vh',
            }}

        >
            <ButtonDemo
                style={{
                    fontSize: '20px',
                    width: '250px',
                    height: '70px',
                    // fontWeight: 'italic'
                }}/>
        </div>
    )
}
const App: React.FC = () => {
    useNavigateHome()
    logEvent(analytics, 'page_view', {page_title: 'homepage'})

    const repo_name = "ds-laia-homepage"
    const [textDisplay, setTextDisplay] = useState<boolean>(false);
    return (
        <div>
            <Homepage/>


            <SectionChart/>
            <SectionBug/>

            <SectionCookieLess/>

            <Bottom/>

        </div>
    )

};
export default App;