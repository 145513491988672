import {Anchor, Avatar, Button, Col, ConfigProvider, Layout, MenuProps, Row, Typography} from 'antd';
import {LinkedinOutlined, UserOutlined} from '@ant-design/icons';
import {Link, useLocation} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import './LayoutWrapper.css';
import {ReactComponent as Logo} from '../icons/base_logo.svg'
import {ButtonDemo} from "./ButtonDemo"
import {HappyProvider} from '@ant-design/happy-work-theme';
import {colorPrimary, fontSizeSmall} from "../global"

const {Header, Content, Footer} = Layout;
const {Title} = Typography;
const {Link: AntdLink} = Anchor;


interface LayoutWrapperProps {
    component: React.ComponentType
    is_header?: boolean;
    // title: string;
}

export function useIsMobile() {
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768)
    useEffect(() => {
        function handleResize() {
            setIsMobile(window.innerWidth < 768)
        }

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])
    return isMobile
}


const LayoutWrapper: React.FC<LayoutWrapperProps> = (
    {
        component: WrappedComponent,
        is_header = false,
    }) => {

    const isMobile = useIsMobile()

    const location = useLocation();
    let title = location.pathname.substring(1);
    if (title === '') {
        title = 'homepage';
    }
    console.log(title, 'title');
    const title_key = title.charAt(0).toUpperCase() + title.slice(1);

    const items: MenuProps['items'] = [

            {
                label: <Link to="/alumni">Alumni</Link>,
                key: 'Alumni',
            },
            {
                label: <Link to="/album">Album</Link>,
                key: 'Album',
            },
            {
                label: <Link to="/plans">Plans</Link>,
                key: 'Plans',
            }, {
                label: <Avatar
                    style={{
                        backgroundColor: 'lightgreen',
                        cursor: 'pointer',
                        width: '35px',
                        height: '35px'
                    }}
                    icon={<UserOutlined style={{color: 'white'}}/>}
                />,
                key: 'User',
                children: [
                    {
                        label: <Link to="/profile">Your profile</Link>,
                        key: 'profile',
                    },
                    {
                        label: 'Log out',
                        key: 'logout',
                        // onClick: logOut,
                    },
                ],
            }
        ]
    ;
    return (
        <HappyProvider>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: colorPrimary,
                        borderRadius: 10,
                        padding: 20,
                        fontFamily: 'Roboto',
                        fontWeightStrong: 1,
                        fontSize: 20,
                        // fontSizeHeading1: 50,
                        fontSizeHeading1: isMobile ? 40 : 55,

                        fontSizeHeading2: isMobile ? 25 : 33,
                        fontSizeHeading3: isMobile ? 22 : 29,


                    },
                }}
            >
                <Layout className="layout content" style={{
                    backgroundColor: 'white',
                    // backgroundImage: `url(${BottomLeftBackround}), url(${TopRightBackground})`,
                    // backgroundPosition: '-5vw 60vh, 80vw -8vh',
                    // backgroundRepeat: 'no-repeat, no-repeat',
                    // height: '100vh',
                    width: '99vw',
                    // animation: 'fadeInAnimation 1s linear',
                }}>
                    <Header style={{
                        backgroundColor: 'white',
                        borderBottom: '1px solid lightgreen',
                        height: '10vh',
                        paddingBottom: '1px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%'
                    }}>
                        <Row style={{width: '100%'}}>
                            <Col
                                span={12}
                                style={{
                                    // display: 'flex',
                                    // justifyContent: 'flex-start',
                                    marginLeft: isMobile ? '-190px' : '-160px',
                                    marginRight: isMobile ? '190px' : '160px',
                                    // marginTop: '0px',
                                    // alignItems: 'center',
                                    // height: '100%'
                                }}
                            >
                                <Link to="/">
                                    <Logo style={{
                                        width: '420px',
                                    }}
                                    />
                                </Link>
                            </Col>

                            <Col span={12}
                                 style={{
                                     display: 'flex',
                                     justifyContent: 'flex-end',
                                     alignItems: 'center',
                                     height: '100%'
                                 }}

                            >
                                {isMobile? null:
                                <ButtonDemo
                                    style={{
                                        width: '178px',
                                        height: '50px',
                                        fontSize: '15px',


                                    }}

                                />
                                }
                            </Col>


                        </Row>


                    </Header>
                    <WrappedComponent/>
                    <Footer style={{
                        backgroundColor: 'white',
                        borderTop: '1px solid lightgreen',
                        height: '15vh',
                        display: 'flex',
                        // justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        width: '100%'
                    }}>
                        <Col
                            span={8}
                            style={{
                                display: 'flex',
                                // justifyContent: 'flex-start',
                                // justifyContent: 'center',
                                flexDirection: 'column'
                            }}

                        >
                            <p
                                style={{
                                    fontSize: fontSizeSmall,
                                }}

                            >
                                <strong>Laia hub analytics</strong> <br/>
                                Av/ Tarradellas, 38, Barcelona, Spain <br/>
                                info@laiahub.ai

                            </p>
                            <Link
                                to={'https://www.linkedin.com/company/laiahub/'}
                                target='_blank'

                            >
                                <LinkedinOutlined/>
                            </Link>
                        </Col>
                        <Col
                            span={8}
                            style={{
                                display: 'flex',
                                // justifyContent: 'center',
                                // alignItems: 'center',
                            }}
                        >
                        </Col>
                        <Col
                            span={8}
                            style={{
                                display: 'flex',
                                // justifyContent: 'flex-start',
                                alignItems: 'start',
                                flexDirection: 'column'
                            }}
                        >
                            {/*<Button*/}
                            {/*    type='text'*/}
                            {/*    style={{*/}
                            {/*        // marginTop: '10px',*/}

                            {/*        fontSize: fontSizeSmall,*/}

                            {/*    }}>*/}
                            {/*    Terms*/}
                            {/*</Button>*/}
                            <Link to={'/privacy-policy'}>
                            <Button
                                type='text'
                                style={{
                                    // marginTop: '10px',
                                    fontSize: fontSizeSmall,
                                }}

                            >
                                Privacy policy
                            </Button>
                            </Link>

                        </Col>
                    </Footer>
                </Layout>
            </ConfigProvider>
        </HappyProvider>
    );
};

export default LayoutWrapper;