import React, {useEffect, useState} from 'react'
import Plotly from 'plotly.js-dist-min'
import Plot from 'react-plotly.js'
import {useIsMobile} from "./LayoutWrapper"

export const plotly_config = {
    displayModeBar: false,
    responsive: true
}

interface PrettyPlotProps {
    plotData: any
    style?: React.CSSProperties
    // showModal?: React.Dispatch<React.SetStateAction<any>>
}

const PrettyPlot: React.FC<PrettyPlotProps> = (
    {
        plotData, style,
        // showModal = () => {
        // },
    }) => {
    const isMobile = useIsMobile()
    const [currentPlotData, setCurrentPlotData] = useState<any>(null)
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

    function showModal(plotData: any) {
        setCurrentPlotData(plotData)
        setIsModalVisible(true)
    }

    console.log({plotData})
    if (typeof plotData === 'string')
        plotData = JSON.parse(plotData)
    console.log({plotData})
    const [hover, setHover] = useState(false)
    const [plotId, setPlotId] = useState<string>('')
    const [showTable, setShowTable] = useState(false)

    useEffect(() => {
        setPlotId('plotId-' + Math.random().toString(36).substr(2, 9))
    }, [])
    if (plotData == null) return null
    const downloadPlotAsImage = (plotId: string) => {
        const plotlyChart = document.getElementById(plotId)
        console.log('plotlyChart', plotlyChart)
        if (plotlyChart instanceof HTMLElement) {
            let filename = 'plot_'
            if (plotData.layout.title && typeof plotData.layout.title !== 'string') {
                if (plotData.layout.title.text) {
                    filename += plotData.layout.title.text.toLowerCase().replace(/ /g, '_')
                }
            }
            Plotly.downloadImage(plotlyChart,
                {
                    format: 'png',
                    filename,
                    width: 700,
                    height: 1000
                })
        } else {
            console.error('Unable to find the plot element.')
        }
    }

    let {layout} = plotData
    if (isMobile) {
        layout.font.size = 10
        layout.margin = {
            t: 30,
            l: 5,
            r: 5,
            b: 80
        }
    }


    return (
        <>
            <div
                style={{
                    borderRadius: 4,
                    border: '0.1px solid',
                    borderColor: 'whitesmoke',
                    backgroundColor: 'white',
// display: 'flex',
//                     height: isMobile ? '10vh' : undefined,
                    fontSize: '10px'

                }}
            >


                <Plot
                    divId={plotId}
                    data={plotData.data}
                    layout={layout}
                    style={{
                        height: isMobile ? '35vh' : undefined,

                        // width: '30vw',
                        // height: '100%'
                    }}
                    config={plotly_config}
                />

            </div>

        </>
    )
}

export default PrettyPlot
