import React, {useEffect} from 'react';
import {Button, Layout, Typography} from 'antd';
import {fetchBackend} from "../utils/backend";
import {ReactComponent as Logo} from '../icons/base_logo.svg'

const {Title} = Typography;

const {Content, Footer} = Layout;

const Dev = () => {
    console.log(window.location, 'pathname');

    useEffect(() => {
        fetchBackend('get_headers');
    }, []);
    return (
            <Content className="content"
                     style={ {
                         display: 'flex',
                         flexDirection: 'column',
                         // justifyContent: 'center',
                         alignItems: 'center',
                         paddingTop: ' 10vh',
                         backgroundColor: 'white',
                         // height: '60vh'
                     }}>

                <Logo style={{
                                        width: '8000px',
                                    }}
                                    />
                <Button type="primary"
                    // onClick={() => setShowAddAlumniModal(!showAddAlumniModal)}

                        style={ {
                            // borderRadius: '50%',
                            width: '150px',
                            height: '75px',
                        }}
                > test </Button>
                {/*<p> {HeaderText}  </p>*/}
            </Content>
    );
};

export default Dev;