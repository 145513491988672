import {getAuth} from 'firebase/auth'
import {initializeApp} from 'firebase/app'
import {getAnalytics} from "firebase/analytics"

const firebaseConfig = {
    apiKey: "AIzaSyAWLc2tJWpNqdWG0_yMymVTHECIGRT3dWU",
    authDomain: "boschmonart.firebaseapp.com",
    projectId: "boschmonart",
    storageBucket: "boschmonart.appspot.com",
    messagingSenderId: "205162347154",
    appId: "1:205162347154:web:5af4ae57ac1463460b044b",
    measurementId: "G-BNN8CSPCMP"
}

export const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app);
export const auth = getAuth(app)
